export default {
  messages: {
    ru: {
      photoReport: 'Фотоотчёт',
      videoReport: 'Видеоотчёт',
      reviewLink: 'Ссылка для просмотра',
      video: 'видео',
      link: 'ссылка',
      linkIsMissed: 'ссылка отсутствует',
    },
    uk: {
      photoReport: 'Фотозвіт',
      videoReport: 'Відеозвіт',
      reviewLink: 'Посилання для перегляду',
      video: 'відео',
      link: 'посилання',
      linkIsMissed: 'посилання відсутнє',
    },
  },
};
