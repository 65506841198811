export default {
	messages: {
		ru: {
			'Проверка кузова авто': 'Проверка кузова авто',
			'Толщина ЛКП, мкм': 'Толщина ЛКП, мкм',
			'Коррозия': 'Коррозия',
			'Наличие следов кузовного ремонта': 'Наличие следов кузовного ремонта',
			'Перекраска': 'Перекраска',
			'Шпатлевка': 'Шпатлевка',
			'Замена': 'Замена',
			'Рихтовка': 'Рихтовка',
			'Сколи': 'Сколы',
			'Подряпини': 'Царапины',
			'Комментарий': 'Комментарий',
			'Остекление': 'Остекление',
			'Оригинальные стекла? Наличие сколов, трещин и других повреждений.': 'Оригинальные стекла? Наличие сколов, трещин и других повреждений.',
			'Шины и диски': 'Шины и диски',
			'Состояние, равномерность износа и остаточная высота протекторов шин. Состояние дисков: битые, гнутые, поврежденные, наличие сколов и трещин.': 'Состояние, равномерность износа и остаточная высота протекторов шин. Состояние дисков: битые, гнутые, поврежденные, наличие сколов и трещин.',
			'Резюме мастера': 'Резюме мастера',
			'Другие замечания': 'Другие замечания',
			'Пластиковые элементы невозможно проверить на толщину ЛКП.': 'Пластиковые элементы невозможно проверить на толщину ЛКП.'
		},
		uk: {
			'Проверка кузова авто': 'Перевірка кузова авто',
			'Толщина ЛКП, мкм': 'Tовщина ЛФП, мкм',
			'Коррозия': 'Корозія',
			'Наличие следов кузовного ремонта': 'Наявність слідів кузовного ремонту',
			'Перекраска': 'Фарбування',
			'Шпатлевка': 'Шпаклівка',
			'Замена': 'Заміна',
			'Рихтовка': 'Рихтування',
			'Сколи': 'Сколи',
			'Подряпини': 'Подряпини',
			'Комментарий': 'Коментар',
			'Остекление': 'Cкління',
			'Оригинальные стекла? Наличие сколов, трещин и других повреждений.': 'Оригінальність стекол? Наявність сколів, тріщин та інших ушкоджень.',
			'Шины и диски': 'Шини та диски',
			'Состояние, равномерность износа и остаточная высота протекторов шин. Состояние дисков: битые, гнутые, поврежденные, наличие сколов и трещин.': 'Стан, рівномірність зносу і залишкова висота протекторів шин. Стан дисків : биті, гнуті, пошкоджені, наявність сколів і тріщин.',
			'Резюме мастера': 'Резюме майстра',
			'Другие замечания': 'Інші зауваження',
			'Пластиковые элементы невозможно проверить на толщину ЛКП.': 'Пластикові елементи не можливо перевірити на товщину ЛФП.'
		}
	}
};
