import { mapGetters } from 'vuex';

export default {
  name: 'CompressionMeasurement',
  i18n: require('./i18n').default,
  data() {
    return {
      selected: {
        compressionMeasurement: {
          cylinder1: {
            value: undefined,
            condition: undefined,
          },
          cylinder2: {
            value: undefined,
            condition: undefined,
          },
          cylinder3: {
            value: undefined,
            condition: undefined,
          },
          cylinder4: {
            value: undefined,
            condition: undefined,
          },
          cylinder5: {
            value: undefined,
            condition: undefined,
          },
          cylinder6: {
            value: undefined,
            condition: undefined,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'TechnicalCheck/autotestData',
    }),
  },
  props: ['compressionMeasurement', 'pageBreak', 'isOnSiteVerifying'],
  created() {
    if (this.compressionMeasurement) {
      this.selected = this.compressionMeasurement;
    }
  },
  watch: {
    selected: {
      handler() {
        this.$parent.$emit('changeCompressionMeasurement', this.selected);
      },
      deep: true,
    },
  },
};
