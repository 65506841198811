export default {
  messages: {
    ru: {
      знижений: 'понижен',
      норма: 'норма',
      підвищений: 'повышен',
      'Замір компресії': 'Замер компрессии',
      циліндр: 'цилиндр',
    },
    uk: {
      знижений: 'знижений',
      норма: 'норма',
      підвищений: 'підвищений',
      'Замір компресії': 'Замір компресії',
      циліндр: 'циліндр',
    },
  },
};
