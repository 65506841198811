export default {
	messages: {
		ru: {
			'Результаты технической проверки': 'Результаты технической проверки',
			'Подсказка': 'Подсказка',
			'Позволяет перейти на любой тематический раздел или подраздел сайта без использования поиска': 'Позволяет перейти на любой тематический раздел или подраздел сайта без использования поиска',
			'AUTO.RIA — лидер автомобильной интернет-торговли': 'AUTO.RIA — лидер автомобильной интернет-торговли',
			'К сожалению, доступ к протоколу диагностики не доступен для Вас.': 'К сожалению, доступ к протоколу диагностики не доступен для Вас.',
			'Если Вы заказывали диагностику данного авто, авторизуйтесь как пользователь,': 'Если Вы заказывали диагностику данного авто, авторизуйтесь как пользователь,',
			'который оставлял заявку': 'который оставлял заявку',
			'Также Вы можете воспользоваться другими услугами программы': 'Также Вы можете воспользоваться другими услугами программы',
			'Перевірені': 'Проверенные'
		},
		uk: {
			'Результаты технической проверки': 'Результати технічної перевірки',
			'Подсказка': 'Підказка',
			'Позволяет перейти на любой тематический раздел или подраздел сайта без использования поиска': 'Дозволяє перейти на будь-який тематичний розділ або підрозділ сайту без використання пошуку',
			'AUTO.RIA — лидер автомобильной интернет-торговли': 'AUTO.RIA — лідер автомобільної інтернет-торгівлі',
			'К сожалению, доступ к протоколу диагностики не доступен для Вас.': 'На жаль, доступ до протоколу діагностики не доступний для Вас.',
			'Если Вы заказывали диагностику данного авто, авторизуйтесь как пользователь,': 'Якщо Ви замовляли діагностику даного авто, авторизуйтесь як користувач,',
			'который оставлял заявку': 'що залишав заявку',
			'Также Вы можете воспользоваться другими услугами программы': 'Також Ви можете скористатись іншими послугами програми',
			'Перевірені': 'Перевірені'
		}
	}
};
