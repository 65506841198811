import { mapGetters } from 'vuex';

import { ON_SITE_VERIFYING_STATION_ID } from '../../../../constants/autotest';

export default {
  name: 'InfoSTO',
  i18n: require('./i18n').default,
  data() {
    return {
      selected: {},
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'TechnicalCheck/autotestData',
    }),
    onSiteInspection() {
      return this.autotestData?.stoData?.station_id === ON_SITE_VERIFYING_STATION_ID;
    },
  },
};
