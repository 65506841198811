import {mapGetters} from 'vuex';

export default {
  name: 'InteriorInspection',
  i18n: require('./i18n').default,
  data: function () {
    return {
      selected: {
        interiorElements: {
          heaterConditioner: {
            value: undefined,
            comment: ''
          },
          glassLifts: {
            value: undefined,
            comment: ''
          },
          seatCondition: {
            value: undefined,
            options: {
              wornOut: undefined,
              burn: undefined,
              shabby: undefined,
              dirty: undefined
            },
            comment: ''
          },
          seatAdjustment: {
            value: undefined,
            comment: ''
          },
          mirrors: {
            value: undefined,
            comment: ''
          },
          windshieldWiper: {
            value: undefined,
            comment: ''
          },
          warningsOnTheDashboard: {
            value: undefined,
            comment: ''
          },
          buttonsLeversSwitches: {
            value: undefined,
            comment: ''
          },
          helm: {
            value: undefined,
            options: {
              wornOut: undefined,
              inTheCase: undefined
            },
            comment: ''
          },
          audioVideo: {
            value: undefined,
            comment: ''
          },
          otherSystems: {
            value: undefined,
            comment: ''
          },
          upholsteryMateria: {
            value: undefined,
            options: {
              shabby: undefined,
              burn: undefined,
              cracks: undefined,
              dirty: undefined
            },
            comment: ''
          },
          extraneousSmells: {
            value: undefined,
            comment: ''
          }
        },
        otherRemarks: ''
      }
    }
  },
  props: ['interiorInspection', 'pageBreak'],
  created() {
    if (this.interiorInspection) {
      this.selected = this.interiorInspection;
    }
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'TechnicalCheck/autotestData'
    })
  },
  watch: {
    'selected': {
      handler() {
        this.$parent.$emit('changeInteriorInspection', this.selected);
      },
      deep: true
    },
    'selected.interiorElements.seatCondition.value': {
      handler(val) {
        if (val) {
          for (let i in this.selected.interiorElements.seatCondition.options) {
            this.selected.interiorElements.seatCondition.options[i] = false;
          }
          this.selected.interiorElements.seatCondition.value = true;
        }
      }
    },
    'selected.interiorElements.seatCondition.options.wornOut': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.seatCondition.value = false;
        }
      }
    },
    'selected.interiorElements.seatCondition.options.burn': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.seatCondition.value = false;
        }
      }
    },
    'selected.interiorElements.seatCondition.options.shabby': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.seatCondition.value = false;
        }
      }
    },
    'selected.interiorElements.seatCondition.options.dirty': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.seatCondition.value = false;
        }
      }
    },
    'selected.interiorElements.helm.value': {
      handler(val) {
        if (val) {
          for (let i in this.selected.interiorElements.helm.options) {
            this.selected.interiorElements.helm.options[i] = false;
          }
          this.selected.interiorElements.helm.value = true;
        }
      }
    },
    'selected.interiorElements.helm.options.wornOut': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.helm.value = false;
        }
      }
    },
    'selected.interiorElements.helm.options.inTheCase': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.helm.value = false;
        }
      }
    },
    'selected.interiorElements.upholsteryMateria.value': {
      handler(val) {
        if (val) {
          for (let i in this.selected.interiorElements.upholsteryMateria.options) {
            this.selected.interiorElements.upholsteryMateria.options[i] = false;
          }
          this.selected.interiorElements.upholsteryMateria.value = true;
        }
      }
    },
    'selected.interiorElements.upholsteryMateria.options.shabby': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.upholsteryMateria.value = false;
        }
      }
    },
    'selected.interiorElements.upholsteryMateria.options.burn': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.upholsteryMateria.value = false;
        }
      }
    },
    'selected.interiorElements.upholsteryMateria.options.cracks': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.upholsteryMateria.value = false;
        }
      }
    },
    'selected.interiorElements.upholsteryMateria.options.dirty': {
      handler(val) {
        if (val) {
          this.selected.interiorElements.upholsteryMateria.value = false;
        }
      }
    }
  }
};
