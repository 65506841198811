// пункти комп'ютерної діагностики для електроавто
const ELECTRIC_COMPUTER_DIAGNOSTICS_ITEMS = [
  {
    option: 'batteryCapacity',
    title: 'Ємність основної батареї',
    type: 'checkbox',
  },
  {
    option: 'mileage',
    title: 'Пробіг авто',
    type: 'number',
  },
  {
    option: 'certificates',
    title: 'Сертифікати',
    type: 'checkbox',
  },
  {
    option: 'operatingSystem',
    title: 'Операційна система',
    type: 'checkbox',
  },
  {
    option: 'autopilot',
    title: 'Автопілот',
    type: 'checkbox',
  },
  {
    option: 'modem',
    title: 'Модем',
    type: 'checkbox',
  },
  {
    option: 'navigation',
    title: 'Навігація',
    type: 'checkbox',
  },
  {
    option: 'fastCharging',
    title: 'Швидкісні зарядки',
    type: 'checkbox',
  },
];

// опції стану для пунктів комп'ютерної діагностики електроавто
const ELECTRIC_COMPUTER_DIAGNOSTICS_OPTIONS = [
  {
    id: 1,
    value: 'В нормі',
    type: 'positive',
  },
  {
    id: 2,
    value: 'Рекомендований ремонт',
    type: 'negative',
  },
  {
    id: 3,
    value: 'Рекомендована заміна',
    type: 'negative',
  },
];

// опції, шоб сетапити значення пунктів комп'ютерної діагностики електроавто
const COMPUTER_DIAGNOSTICS_OPTIONS_VALUES = ELECTRIC_COMPUTER_DIAGNOSTICS_ITEMS
  .reduce((acc, { option }) => {
    acc[option] = undefined;
    return acc;
  }, {});

export default {
  ELECTRIC_COMPUTER_DIAGNOSTICS_ITEMS,
  ELECTRIC_COMPUTER_DIAGNOSTICS_OPTIONS,
  COMPUTER_DIAGNOSTICS_OPTIONS_VALUES,
};
