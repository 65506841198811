export default {
	messages: {
		ru: {
			'Тестовая поездка': 'Тестовая поездка',
			'Подвеска': 'Подвеска',
			'посторонние шумы': 'посторонние шумы',
			'пробивание амортизаторов': 'пробивание амортизаторов',
			'норма': 'норма',
			'Разгон': 'Разгон',
			'равномерный': 'равномерный',
			'с провалами': 'с провалами',
			'Переключение КПП': 'Переключение КПП',
			'плавное': 'плавное',
			'рывки': 'рывки',
			'Выхлоп двигателя': 'Выхлоп двигателя',
			'прозрачный': 'прозрачный',
			'сизый дым': 'сизый дым',
			'чёрный дым': 'чёрный дым',
			'Температура двигателя во время езды': 'Температура двигателя во время езды',
			'повышенная': 'повышенная',
			'пониженная': 'пониженная',
			'Сторонние шумы в двигателе': 'Сторонние шумы в двигателе',
			'присутствуют': 'присутствуют',
			'отсутствуют': 'отсутствуют',
			'Шум обшивки и оборудования салона': 'Шум обшивки и оборудования салона',
			'Предупреждения на приборной панели': 'Предупреждения на приборной панели',
			'Положение руля при езде по прямой': 'Положение руля при езде по прямой',
			'ровное': 'ровное',
			'с отклонением': 'с отклонением',
			'Нулевая точка руля': 'Нулевая точка руля',
			'тянет в сторону': 'тянет в сторону',
			'Посторонние запахи в салоне': 'Посторонние запахи в салоне',
			'Резюме тестовой поездки': 'Резюме тестовой поездки'
		},
		uk: {
			'Тестовая поездка': 'Тестова поїздка',
			'Подвеска': 'Підвіска',
			'посторонние шумы': 'сторонні шуми',
			'пробивание амортизаторов': 'пробивання амортизаторів',
			'норма': 'норма',
			'Разгон': 'Розгін',
			'равномерный': 'рівномірний',
			'с провалами': 'з провалами',
			'Переключение КПП': 'Перемикання КПП',
			'плавное': 'плавне',
			'рывки': 'ривки',
			'Выхлоп двигателя': 'Вихлоп двигуна',
			'прозрачный': 'прозорий',
			'сизый дым': 'сизий дим',
			'чёрный дым': 'чорний дим',
			'Температура двигателя во время езды': 'Температура двигуна під час їзди',
			'повышенная': 'підвищена',
			'пониженная': 'знижена',
			'Сторонние шумы в двигателе': 'Сторонні шуми в двигуні',
			'присутствуют': 'присутні',
			'отсутствуют': 'відсутні',
			'Шум обшивки и оборудования салона': 'Шум обшивки і обладнання салону',
			'Предупреждения на приборной панели': 'Попередження на панелі приладів',
			'Положение руля при езде по прямой': 'Положення керма при їзді по прямій',
			'ровное': 'рівне',
			'с отклонением': 'з відхиленням',
			'Нулевая точка руля': 'Нульова точка керма',
			'тянет в сторону': 'тягне в бік',
			'Посторонние запахи в салоне': 'Сторонні запахи в салоні',
			'Резюме тестовой поездки': 'Резюме тестової поїздки'
		}
	}
};
