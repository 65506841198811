export default {
	messages: {
		ru: {
			'Компьютерная диагностика': 'Компьютерная диагностика',
			'Система': 'Система',
			'Выявленные уведомления о неполадках': 'Выявленные уведомления о неполадках',
			'Название системы': 'Название системы',
			'Удалить': 'Удалить',
			'Добавить поле': 'Добавить поле',
			'Комментарии': 'Комментарии'
		},
		uk: {
			'Компьютерная диагностика': 'Комп’ютерна діагностика',
			'Система': 'Система',
			'Выявленные уведомления о неполадках': 'Виявлені повідомлення про неполадки',
			'Название системы': 'Назва системи',
			'Удалить': 'Видалити',
			'Добавить поле': 'Додати поле',
			'Комментарии': 'Коментарі'
		}
	}
};
