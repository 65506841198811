export default {
	messages: {
		ru: {
			'Диагностика ходовой части': 'Диагностика ходовой части',
			'«Галочками» мастер отмечает те детали ходовой, которые требуют ремонта или замены': '«Галочками» мастер отмечает те детали ходовой, которые требуют ремонта или замены',
			'Левая': 'Левая',
			'Правая': 'Правая',
			'Передняя подвеска': 'Передняя подвеска',
			'Задняя подвеска': 'Задняя подвеска',
			'Тормозная система передняя': 'Тормозная система передняя',
			'Тормозная система задняя': 'Тормозная система задняя',
			'Рулевое управление': 'Рулевое управление',
			'Прочие замечания': 'Прочие замечания',
			'Замечания по ходовой авто ...': 'Замечания по ходовой авто ...',
			'runningDiagnostics.absorber': 'Амортизатор',
			'runningDiagnostics.suspensionStrut': 'Опора амортизатора',
			'runningDiagnostics.absorberBumper': 'Пыльник, отбойник амортизатора',
			'runningDiagnostics.leverUpper': 'Рычаг верхний',
			'runningDiagnostics.ballJointLower': 'Шаровая опора нижняя',
			'runningDiagnostics.leverUpperFront': 'Рычаг верхний передний',
			'runningDiagnostics.leverUpperBack': 'Рычаг верхний задний',
			'runningDiagnostics.lowerLever': 'Рычаг нижний',
			'runningDiagnostics.leverLowerFront': 'Рычаг нижний передний',
			'runningDiagnostics.lowerBackLever': 'Рычаг нижний задний',
			'runningDiagnostics.stabilizerBushOuter': 'Втулка стабилизатора наружная',
			'runningDiagnostics.stabilizerBushing': 'Втулка стабилизатора',
			'runningDiagnostics.frontStabilizer': 'Стойка стабилизатора',
			'runningDiagnostics.frontLeverBushing': 'Втулка рычага передняя',
			'runningDiagnostics.bushingOfTheLeverBack': 'Втулка рычага задняя',
			'runningDiagnostics.shrusOut': 'Шрус наружный',
			'runningDiagnostics.shrusIn': 'Шрус внутренний',
			'runningDiagnostics.shrusOutCase': 'Чехол шруса наружного',
			'runningDiagnostics.shrusInCase': 'Чехол шруса внутреннего',
			'runningDiagnostics.frontBeamSleeve': 'Втулка балки передняя',
			'runningDiagnostics.BackBeamSleeve': 'Втулка балки задняя',
			'runningDiagnostics.OmentumSemiaxis': 'Сальник полуоси',
			'runningDiagnostics.semiaxis': 'Полуось',
			'runningDiagnostics.hubBearing': 'Подшипник ступицы',
			'runningDiagnostics.hub': 'Ступица',
			'runningDiagnostics.fistRotary': 'Кулак поворотный',
			'runningDiagnostics.frontEngineMount': 'Опора двигателя передняя',
			'runningDiagnostics.rearEngineMount': 'Опора двигателя задняя',
			'runningDiagnostics.transmissionLifter': 'Опора КПП',
			'runningDiagnostics.tire': 'Шина',
			'runningDiagnostics.cardanShaftBetween': 'Карданный вал промеж.',
			'runningDiagnostics.cardanShaftBetween1': 'Карданный вал промеж.',
			'runningDiagnostics.stabilizer': 'Стабилизатор',
			'runningDiagnostics.spring': 'Пружина',
			'runningDiagnostics.leverLongitudinal': 'Рычаг продольный',
			'runningDiagnostics.linearBushing': 'Втулка продольного рычага',
			'runningDiagnostics.beamSubframe': 'Балка (подрамник)',
			'runningDiagnostics.bushingBeamSubframe': 'Втулка балки (подрамника)',
			'runningDiagnostics.spring1': 'Рессора',
			'runningDiagnostics.springBushing': 'Втулка рессоры',
			'runningDiagnostics.couplingFlange': 'Муфта (фланец)',
			'runningDiagnostics.crossingCardan': 'Крестовина кардана',
			'runningDiagnostics.cardanSuspensionBearing': 'Подвесной подшипник кардана',
			'runningDiagnostics.brakeDisc': 'Диск тормозной',
			'runningDiagnostics.brakePads': 'Колодки тормозные',
			'runningDiagnostics.caliper': 'Суппорт',
			'runningDiagnostics.guideRailsForCalipers': 'Направляющие суппортов',
			'runningDiagnostics.brakeCylinder': 'Тормозной цилиндр',
			'runningDiagnostics.brakeHose': 'Шланг тормозной',
			'runningDiagnostics.brakeTube': 'Трубка тормозная',
			'runningDiagnostics.ABSSensor': 'Датчик ABS',
			'runningDiagnostics.masterBrakeCylinder': 'Главный тормозной цилиндр',
			'runningDiagnostics.brakeFluid': 'Тормозная жидкость',
			'runningDiagnostics.padWearSensor': 'Датчик износа колодок',
			'runningDiagnostics.anthersOfGuideCalipers': 'Пыльники направляющих суппортов',
			'runningDiagnostics.handbrakePads': 'Колодки стояночного тормоза',
			'runningDiagnostics.brakeCable': 'Трос стояночного тормоза',
			'runningDiagnostics.brakePressureRegulator': 'Регулятор давления тормозов',
			'runningDiagnostics.steeringGear': 'Рулевой механизм',
			'runningDiagnostics.steeringRack': 'Рулевая рейка',
			'runningDiagnostics.tieRodEnd': 'Наконечник рулевой тяги',
			'runningDiagnostics.tractionSteering': 'Тяга рулевая',
			'runningDiagnostics.steeringColumn': 'Рулевая колонка',
			'runningDiagnostics.steeringRackCover': 'Чехол рулевой тяги',
			'runningDiagnostics.gearPumpBelt': 'Ремень насоса г/у',
			'runningDiagnostics.hydraulicPump': 'Насос г/у',
			'runningDiagnostics.fluidPump': 'Жидкость насоса г/у',
			'runningDiagnostics.thePendulumArm': 'Маятниковый рычаг',
			'runningDiagnostics.wheelAlignmentAngles': 'Углы установки колес'
		},
		uk: {
			'Диагностика ходовой части': 'Діагностика ходової частини',
			'«Галочками» мастер отмечает те детали ходовой, которые требуют ремонта или замены': '«Галочками» майстер зазначає ті деталі ходової, які вимагають ремонту або заміни',
			'Левая': 'Ліва',
			'Правая': 'Права',
			'Передняя подвеска': 'Передня підвіска',
			'Задняя подвеска': 'Задня підвіска',
			'Тормозная система передняя': 'Гальмівна система передня',
			'Тормозная система задняя': 'Гальмівна система задня',
			'Рулевое управление': 'Рульове управління',
			'Прочие замечания': 'Інші зауваження',
			'Замечания по ходовой авто ...': 'Зауваження по ходовій авто ...',
			'runningDiagnostics.absorber': 'Амортизатор',
			'runningDiagnostics.suspensionStrut': 'Опора амортизатора',
			'runningDiagnostics.absorberBumper': 'Пильовик, відбійник амортизатора',
			'runningDiagnostics.leverUpper': 'Важіль верхній',
			'runningDiagnostics.ballJointLower': 'Кульова опора нижня',
			'runningDiagnostics.leverUpperFront': 'Важіль верхній передній',
			'runningDiagnostics.leverUpperBack': 'Важіль верхній задній',
			'runningDiagnostics.lowerLever': 'Важіль нижній',
			'runningDiagnostics.leverLowerFront': 'Важіль нижній передній',
			'runningDiagnostics.lowerBackLever': 'Важіль нижній задній',
			'runningDiagnostics.stabilizerBushOuter': 'Втулка стабілізатора зовнішня',
			'runningDiagnostics.stabilizerBushing': 'Втулка стабілізатора',
			'runningDiagnostics.frontStabilizer': 'Стійка стабілізатора',
			'runningDiagnostics.frontLeverBushing': 'Втулка важеля передня',
			'runningDiagnostics.bushingOfTheLeverBack': 'Втулка важеля задня',
			'runningDiagnostics.shrusOut': 'Шрус зовнішній',
			'runningDiagnostics.shrusIn': 'Шрус внутрішній',
			'runningDiagnostics.shrusOutCase': 'Чохол шруса зовнішнього',
			'runningDiagnostics.shrusInCase': 'Чохол шруса внутрішнього',
			'runningDiagnostics.frontBeamSleeve': 'Втулка балки передня',
			'runningDiagnostics.BackBeamSleeve': 'Втулка балки задня',
			'runningDiagnostics.OmentumSemiaxis': 'Сальник півосі',
			'runningDiagnostics.semiaxis': 'Піввісь',
			'runningDiagnostics.hubBearing': 'Підшипник маточини (ступицы)',
			'runningDiagnostics.hub': 'Маточина (ступица)',
			'runningDiagnostics.fistRotary': 'Кулак поворотний',
			'runningDiagnostics.frontEngineMount': 'Опора двигуна передня',
			'runningDiagnostics.rearEngineMount': 'Опора двигуна задня',
			'runningDiagnostics.transmissionLifter': 'Опора КПП',
			'runningDiagnostics.tire': 'Шина',
			'runningDiagnostics.cardanShaftBetween': 'Карданний вал проміж.',
			'runningDiagnostics.cardanShaftBetween1': 'Карданний вал проміж.',
			'runningDiagnostics.stabilizer': 'Стабілізатор',
			'runningDiagnostics.spring': 'Пружина',
			'runningDiagnostics.leverLongitudinal': 'Важіль поздовжній',
			'runningDiagnostics.linearBushing': 'Втулка поздовжнього важеля',
			'runningDiagnostics.beamSubframe': 'Балка (підрамник)',
			'runningDiagnostics.bushingBeamSubframe': 'Втулка балки (підрамника)',
			'runningDiagnostics.spring1': 'Ресора',
			'runningDiagnostics.springBushing': 'Втулка ресори',
			'runningDiagnostics.couplingFlange': 'Муфта (фланець)',
			'runningDiagnostics.crossingCardan': 'Хрестовина кардана',
			'runningDiagnostics.cardanSuspensionBearing': 'Підвісний підшипник кардана',
			'runningDiagnostics.brakeDisc': 'Диск гальмівний',
			'runningDiagnostics.brakePads': 'Гальмівні колодки',
			'runningDiagnostics.caliper': 'Супорт',
			'runningDiagnostics.guideRailsForCalipers': 'Направляючі супортів',
			'runningDiagnostics.brakeCylinder': 'Гальмовий циліндр',
			'runningDiagnostics.brakeHose': 'Шланг гальмівний',
			'runningDiagnostics.brakeTube': 'Трубка гальмівна',
			'runningDiagnostics.ABSSensor': 'Датчик ABS',
			'runningDiagnostics.masterBrakeCylinder': 'Головний гальмівний циліндр',
			'runningDiagnostics.brakeFluid': 'Гальмівна рідина',
			'runningDiagnostics.padWearSensor': 'Датчик зносу колодок',
			'runningDiagnostics.anthersOfGuideCalipers': 'Пильовики направляючих супортів',
			'runningDiagnostics.handbrakePads': 'Колодки стоянкового гальма',
			'runningDiagnostics.brakeCable': 'Трос гальма стоянки',
			'runningDiagnostics.brakePressureRegulator': 'Регулятор тиску гальм',
			'runningDiagnostics.steeringGear': 'Рульовий механізм',
			'runningDiagnostics.steeringRack': 'Рульова рейка',
			'runningDiagnostics.tieRodEnd': 'Накінечник рульової тяги',
			'runningDiagnostics.tractionSteering': 'Рульова тяга',
			'runningDiagnostics.steeringColumn': 'Рульова колонка',
			'runningDiagnostics.steeringRackCover': 'Чохол рульової тяги',
			'runningDiagnostics.gearPumpBelt': 'Ремінь насоса г/п',
			'runningDiagnostics.hydraulicPump': 'Насос г/п',
			'runningDiagnostics.fluidPump': 'Рідина насоса г/п',
			'runningDiagnostics.thePendulumArm': 'Маятниковий важіль',
			'runningDiagnostics.wheelAlignmentAngles': 'Кути установки коліс'
		}
	}
};
