export default {
  messages: {
    ru: {
      '№ заявки': '№ заявки',
      'Результаты технической проверки': 'Результаты технической проверки',
      'Виїзна перевірка від Центру перевірок AUTO.RIA': 'Выездная проверка Центра проверок AUTO.RIA',
    },
    uk: {
      '№ заявки': '№ заявки',
      'Результаты технической проверки': 'Результати технічної перевірки',
      'Виїзна перевірка від Центру перевірок AUTO.RIA': 'Виїзна перевірка від Центру перевірок AUTO.RIA',
    },
  },
};
