export default {
	messages: {
		ru: {
			'Эксперты AUTO.RIA выезжают к продавцу': 'Специалисты AUTO.RIA выезжают к продавцу, осматривают салон и кузов авто с толщиномером, двигатель, делают фото-видео и формируют отчет об общем техническом состоянии. Это поможет составить впечатление об авто и выявить следы ДТП или износа деталей. Для углубленной технической проверки на СТО рекомендуем заказать',
			'техническую проверку': 'техническую проверку',
			'Информация о автомобиле': 'Информация о автомобиле',
			'договорная': 'договорная',
			'Пробег': 'Пробег',
			'Местоположение': 'Местоположение',
			'Тип топлива': 'Тип топлива',
			'Тип коробки передач': 'Тип коробки передач',
			'Диагностика авто на СТО с другого сайта': 'Диагностика авто на СТО с другого сайта',
			'Информация о владельце': 'Информация о владельце',
			'Имя владельца': 'Имя владельц',
			'Проверенные телефоны': 'Проверенные телефоны',
			'Другие телефоны': 'Другие телефоны',
			'Информация о проверке': 'Інформація про перевірку'
		},
		uk: {
			'Эксперты AUTO.RIA выезжают к продавцу': 'Спеціалісти AUTO.RIA виїжджають до продавця, оглядають, салон та кузов авто із товщиноміром, двигун, роблять фото-відео і формують звіт про загальний технічний стан. Це допоможе скласти враження про авто і виявити сліди ДТП чи зносу деталей. Для поглибленої технічної перевірки на СТО рекомендуємо замовити',
			'техническую проверку': 'технічну перевірку',
			'Информация о автомобиле': 'Інформація про автомобіль',
			'договорная': 'договірна',
			'Пробег': 'Пробіг',
			'Местоположение': 'Місцезнаходження',
			'Тип топлива': 'Тип палива',
			'Тип коробки передач': 'Тип коробки передач',
			'Диагностика авто на СТО с другого сайта': 'Діагностика авто на СТО з іншого сайту',
			'Информация о владельце': 'Інформація про власника',
			'Имя владельца': 'Імʼя власника',
			'Проверенные телефоны': 'Перевірені телефони',
			'Другие телефоны': 'Інші телефони',
			'Информация о проверке': 'Інформація про перевірку'
		}
	}
};
