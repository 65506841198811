export default {
	messages: {
		ru: {
			'Для того, щоб детально розуміти актуальний технічний стан авто,': 'Для того чтобы подробно понимать актуальное техническое состояние авто,',
			'рекомендуємо замовити технічну перевірку на СТО': 'рекомендуем заказать техническую проверку на СТО',
			'Приклад звіту': 'Пример отчета',
			'Замовити перевірку': 'Заказать проверку',
		},
		uk: {
			'Для того, щоб детально розуміти актуальний технічний стан авто,': 'Для того, щоб детально розуміти актуальний технічний стан авто,',
			'рекомендуємо замовити технічну перевірку на СТО': 'рекомендуємо замовити технічну перевірку на СТО',
			'Приклад звіту': 'Приклад звіту',
			'Замовити перевірку': 'Замовити перевірку',
		}
	}
};
