import {mapGetters} from 'vuex';

const MAX_MILEAGE = 999999;

export default {
	name: 'InfoBase',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				mileage: undefined,
				VIN: '',
				bodyRemove: undefined
			},
			errorMsg: false
		};
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'TechnicalCheck/autotestData'
		})
	},
	methods: {},
	props: ['infoBase'],
	created() {
		if (this.infoBase) {
			this.selected = this.infoBase;
		}
	},
	watch: {
		'selected': {
			handler(val, oldVal) {
				Math.abs(this.selected['mileage']) > MAX_MILEAGE && (this.selected['mileage'] = MAX_MILEAGE);
				(this.selected['VIN'].length > 17) && (this.selected['VIN'] = this.selected['VIN'].substr(0, 17));
				(this.selected['VIN'].length > 0) && (this.selected['VIN'] = this.selected['VIN'].toUpperCase());
				if (this.selected['VIN'].match(/(q|o|i)/i) || this.selected['VIN'].match(/(.)\1{10}/)) {
					this.selected['VIN'] = this.selected['VIN'].replace(/(q|o|i)/ig, '');
					this.selected['VIN'] = this.selected['VIN'].replace(/(.)\1{9}/, '');
					if (!this.errorMsg) {
						this.errorMsg = true;
						setTimeout(function () {
							this.errorMsg = false;
						}.bind(this), 2000);
					}
				}

				if (this.selected['VIN'].length && !this.selected['VIN'][this.selected['VIN'].length - 1].match(/[a-z0-9]/i)) {
					this.selected['VIN'] = this.selected['VIN'].slice(0, this.selected['VIN'].length - 1);
					if (!this.errorMsg) {
						this.errorMsg = true;
						setTimeout(function () {
							this.errorMsg = false;
						}.bind(this), 2000);
					}
				}

				this.$parent.$emit('changeBaseInfo', this.selected);
			},
			deep: true
		}
	}
};
