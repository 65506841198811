import { mapGetters, mapActions } from 'vuex';
import crypto from 'crypto';
import getFileData from '../../../../helpers/getFileData';
import _laxios from '../../../../store/_laxios/index';

export default {
  data() {
    return {
      modalFlag: true,
    };
  },
  props: ['index', 'item', 'reportId', 'lastSortingIndex'],
  computed: {
    ...mapGetters({
      autotestData: 'TechnicalCheck/autotestData',
    }),
    sessionId() {
      return String([(Math.random() * 1000000).toFixed(0), new Date().getTime()].join('-'));
    },
  },
  async mounted() {
    this.setPreview();
    this.upload();
  },
  methods: {
    ...mapActions({
      setNewReportPhoto: 'others/autotest/setNewReportPhoto',
      recognizePhoto: 'others/autotest/recognizePhoto',
    }),
    modal() {
      // чую шо костыльнул, но куда ж без этого!!!
      if (this.modalFlag) {
        this.$emit('onShowModal', true);
      }
    },
    async setPreview() {
      if (!this.item.path) {
        const { image } = this.item;
        const fileContent = await getFileData(image).catch(console.error);
        if (fileContent) {
          this.$refs.preview.src = fileContent;
        }
      }
    },
    remove() {
      this.$emit('delete', this.index, this.item.report_photo_id);
    },
    imgIdPath(id) {
      return `${Math.floor(id / 10000)}/${Math.floor(id / 100)}/${id}`;
    },
    async upload() {
      if (!this.item.path) {
        this.modalFlag = false;

        const uploadImgHostnameArr = [4, 1];
        const randomIndex = Math.floor(Math.random() * uploadImgHostnameArr.length);
        const hostname = uploadImgHostnameArr[randomIndex];

        try {
          const newSortingIndex = this.lastSortingIndex ? (this.lastSortingIndex + 1) : 0;
          const newPhoto = await this.setNewReportPhoto({
            reportId: this.reportId,
            sortingIndex: newSortingIndex,
          });
          const newPhotoId = newPhoto?.data?.photoId;

          if (newPhotoId) {
            const formData = new FormData();
            const { image } = this.item;
            const photoSection = 'auto/autotest-report';
            const reportIdHash = crypto.createHash('md5').update(`${this.reportId}`).digest('hex');

            formData.append('file0', image);
            formData.append('photoId', newPhotoId);
            formData.append('reportId', this.reportId);
            formData.append('hash', reportIdHash);
            formData.append('area', photoSection);

            formData.append('sessionId', this.sessionId);
            formData.append('host', `${hostname}.riastatic.com`);

            const uploading = await _laxios.addPhoto
              .expandUrl({ staticId: hostname })
              .request({ data: formData })
              .then((res) => res?.[0]?.data)
              .catch((err) => {
                const errPath = `${__filename}/_laxios.addPhoto(${newPhotoId}, ${hostname})`;
                console.error(`ERROR: ${err?.message}, ${errPath}`);
              });

            if (uploading?.photoId) {
              let { hash } = uploading.source;

              const splittedName = (uploading?.name || '').split('.');
              const ext = splittedName.length > 1 ? splittedName[splittedName.length - 1] : 'jpg';

              this.item.report_photo_id = newPhoto?.data?.reportPhotoId;
              this.item.path = `${this.imgIdPath(newPhotoId)}/${newPhotoId}fx.${ext}`;
              hash = `${hash}.${ext}`;
              this.$emit('upload', this.index, hash, this.item.path);
              // відправляємо в чергу 'autophotosCto' для розпізнавання AI
              await this.recognizePhoto(newPhotoId);
              setTimeout(() => {
                this.modalFlag = true;
              }, 1000);
            }
          }
        } catch (err) {
          console.error(`ERROR: ${err?.message}, PATH: ${__filename}/upload()`);
        }
      }
    },
  },
};
