export default {
	messages: {
		ru: {
			'Проверка внешних световых приборов': 'Проверка внешних световых приборов',
			'Передние световые приборы': 'Передние световые приборы',
			'Левая сторона': 'Левая сторона',
			'если смотреть на автомобиль': 'если смотреть на автомобиль',
			'checking.light.near': 'Ближний',
			'checking.light.further': 'Дальний',
			'checking.light.turn': 'Поворот',
			'checking.light.dimension': 'Габарит',
			'checking.light.antiFogLamp': 'Противотуманка',
			'checking.light.reverse': 'Задний ход',
			'checking.light.stop': 'Стоп',
			'checking.light.licensePlate': 'Номерной знак',
			'работает': 'работает',
			'не работает': 'не работает',
			'Правая сторона': 'Правая сторона',
			'Задние световые приборы': 'Задние световые приборы',
			'Регулятор наклона угла света': 'Регулятор наклона угла света',
			'Другие замечания': 'Другие замечания'
		},
		uk: {
			'Проверка внешних световых приборов': 'Перевірка зовнішніх світлових приладів',
			'Передние световые приборы': 'Передні світлові прилади',
			'Левая сторона': 'Ліва сторона',
			'если смотреть на автомобиль': 'якщо дивитися на автомобіль',
			'checking.light.near': 'Ближнє',
			'checking.light.further': 'Дальнє',
			'checking.light.turn': 'Поворот',
			'checking.light.dimension': 'Габарит',
			'checking.light.antiFogLamp': 'Противотуманка',
			'checking.light.reverse': 'Задній хід',
			'checking.light.stop': 'Стоп',
			'checking.light.licensePlate': 'Номерний знак',
			'работает': 'працює',
			'не работает': 'не працює',
			'Правая сторона': 'Права сторона',
			'Задние световые приборы': 'Задні світлові прилади',
			'Регулятор наклона угла света': 'Регулятор нахилу кута світла',
			'Другие замечания': 'Інші зауваження'
		}
	}
};
