import {mapGetters} from 'vuex';

export default {
	name: 'CheckingCollapsConvergence',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				front: {
					sum: '',
					half: {
						'left': '',
						'right': ''
					},
					angle: {
						'left': '',
						'right': ''
					},
					custer: {
						'left': '',
						'right': ''
					}
				},
				rear: {
					sum: '',
					half: {
						'left': '',
						'right': ''
					},
					angle: {
						'left': '',
						'right': ''
					}
				},
				conditionCollapse: undefined,
				conditionConvergence: undefined,
				resume: ''
			}
		};
	},
	props: ['checkingCollapsConvergence', 'pageBreak'],
	created() {
		if (this.checkingCollapsConvergence) {
			this.selected = this.checkingCollapsConvergence;
		}
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'TechnicalCheck/autotestData'
		})
	},
	watch: {
		'selected': {
			handler() {
				this.$parent.$emit('changeCheckingCollapsConvergence', this.selected);
			},
			deep: true
		}
	}

};
