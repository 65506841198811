import {mapGetters} from 'vuex';

export default {
	name: 'RunningDiagnostics',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				checkBoxes: [],
				otherRemarks: ''
			}
		};
	},
	props: ['runningDiagnostics', 'pageBreak'],
	created() {
		if (this.runningDiagnostics) {
			this.selected = this.runningDiagnostics;
		}
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'TechnicalCheck/autotestData',
			checkBoxes: 'TechnicalCheck/checkBoxes'
		})
	},
	watch: {
		'selected': {
			handler() {
				this.$parent.$emit('changeRunningDiagnostics', this.selected);
			},
			deep: true
		}
	}
};
