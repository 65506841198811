export function callback(file, callback) {
	try {
		let reader = new FileReader();
		reader.onload = function ({target: {result} = {}} = {}) {
			callback(result)
		};
		reader.readAsDataURL(file);
	} catch (error) {
		console.error(error)
	}
}

export function promise(file) {
	return new Promise((resolve, reject) => {
		try {
			let reader = new FileReader();
			reader.onload = function ({target: {result} = {}} = {}) {
				resolve(result)
			};
			reader.readAsDataURL(file);
		} catch (error) {
			reject(error)
		}
	});
}

export default promise
