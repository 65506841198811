import {mapGetters} from 'vuex';

export default {
	name: 'TestDrive',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				suspension: undefined,
				overclocking: undefined,
				changeover: undefined,
				smokiness: undefined,
				engineTemperature: undefined,
				engineNoise: undefined,
				noiseSkin: undefined,
				warningsOnTheDashboard: undefined,
				steeringPosition: undefined,
				zeroPoint: undefined,
				foreignOdors: undefined,
				summary: ''
			}
		};
	},
	props: ['testDrive', 'pageBreak'],
	created() {
		if (this.testDrive) {
			this.selected = this.testDrive;
		}
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'TechnicalCheck/autotestData'
		})
	},
	watch: {
		'selected': {
			handler() {
				this.$parent.$emit('changeTestDrive', this.selected);
			},
			deep: true
		}
	}
};
