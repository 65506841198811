export default {
	messages: {
		ru: {
			'Проверка развал-схождения': 'Проверка развал-схождения',
			'Передний мост': 'Передний мост',
			'Левая': 'Левая',
			'Правая': 'Правая',
			'Суммарное схождение': 'Суммарное схождение',
			'Полусхождение': 'Полусхождение',
			'Угол развала': 'Угол развала',
			'Кастер': 'Кастер',
			'Задний мост': 'Задний мост',
			'Состояние развала': 'Состояние развала',
			'в норме': 'в норме',
			'необходима регулировка': 'необходима регулировка',
			'регулировке не подлежит': 'регулировке не подлежит',
			'Состояние схождения': 'Состояние схождения',
			'Резюме мастера': 'Резюме мастера'
		},
		uk: {
			'Проверка развал-схождения': 'Перевірка розвал-сходження',
			'Передний мост': 'Передній міст',
			'Левая': 'Ліва',
			'Правая': 'Права',
			'Суммарное схождение': 'Сумарне сходження',
			'Полусхождение': 'Півсходження',
			'Угол развала': 'Кут розвалу',
			'Кастер': 'Кастер',
			'Задний мост': 'Задній міст',
			'Состояние развала': 'Стан розвалу',
			'в норме': 'в нормі',
			'необходима регулировка': 'необхідне регулювання',
			'регулировке не подлежит': 'регулюванню не підлягає',
			'Состояние схождения': 'Стан сходження',
			'Резюме мастера': 'Резюме майстра'
		}
	}
};
