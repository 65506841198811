export default {
	messages: {
		ru: {
			'Диагностику проводил': 'Диагностику проводил',
			'ФИО мастера': 'ФИО мастера',
			'Подтверждаю достоверность данных в этом отчете (ФИО)': 'Подтверждаю достоверность данных в этом отчете (ФИО)',
			'Сохранить': 'Сохранить',
			'Спасибо! Oтчет успешно заполнен и отправлен.': 'Спасибо! Oтчёт успешно заполнен и отправлен.',
			'Произошла ошибка сохранения отчета! Обратитесь к менеджеру по работе с партнерами  услуги "Перевірені авто" на  AUTO.RIA, тел: (067) 430-66-19': 'Произошла ошибка сохранения отчета! Обратитесь к менеджеру по работе с партнерами  услуги "Перевірені авто" на  AUTO.RIA, тел: (067) 430-66-19',
			'Для сохранения отчёта Вам необходимо заполнить ФИО и подтвердить достоверность данных в этом отчете!': 'Для сохранения отчёта Вам необходимо заполнить ФИО и подтвердить достоверность данных в этом отчете!',
			'версия': 'версия'
		},
		uk: {
			'Диагностику проводил': 'Діагностику проводив',
			'ФИО мастера': 'ПІБ майстра',
			'Подтверждаю достоверность данных в этом отчете (ФИО)': 'Підтверджую достовірність даних в цьому звіті (ПІБ)',
			'Сохранить': 'Зберегти',
			'Спасибо! Oтчет успешно заполнен и отправлен.': 'Дякуємо! Звіт успішно заповнений і відправлений.',
			'Произошла ошибка сохранения отчета! Обратитесь к менеджеру по работе с партнерами  услуги "Перевірені авто" на  AUTO.RIA, тел: (067) 430-66-19': 'Сталася помилка збереження звіту! Зверніться до менеджера по роботі з партнерами послуги "Перевірені авто" на AUTO.RIA, тел: (067) 430-66-19',
			'Для сохранения отчёта Вам необходимо заполнить ФИО и подтвердить достоверность данных в этом отчете!': 'Для збереження звіту Вам необхідно заповнити ПІБ і підтвердити достовірність даних в цьому звіті!',
			'версия': 'версія'
		}
	}
};
