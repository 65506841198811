export default {
	messages: {
		ru: {
			'Осмотр двигателя': 'Осмотр двигателя',
			'Работа двигателя': 'Работа двигателя',
			'норма': 'норма',
			'вибрация': 'вибрация',
			'шум': 'шум',
			'Охлаждающая жидкость': 'Охлаждающая жидкость',
			'прозрачная и чистая': 'прозрачная и чистая',
			'помутневшая': 'помутневшая',
			'отсутствуют': 'отсутствуют',
			'присутствуют': 'присутствуют',
			'вкрапления масла': 'вкрапления масла',
			'понижен': 'понижен',
			'уровень антифриза': 'уровень антифриза',
			'Герметичность двигателя': 'Герметичность двигателя',
			'нет подтёков': 'нет подтёков',
			'есть подтёки': 'есть подтёки',
			'масла из сальников': 'масла из сальников',
			'масла соединительных прокладок': 'масла соединительных прокладок',
			'Маслозаливная горловина': 'Маслозаливная горловина',
			'картерные газы': 'картерные газы',
			'эмульсия': 'эмульсия',
			'Уровень масла в двигателе': 'Уровень масла в двигателе',
			'повышен': 'повышен',
			'Старт мотора': 'Старт мотора',
			'плохо заводится': 'плохо заводится',
			'Выхлоп двигателя': 'Выхлоп двигателя',
			'прозрачный': 'прозрачный',
			'сизый дым': 'сизый дым',
			'чёрный дым': 'чёрный дым',
			'Работа на холостом ходу': 'Работа на холостом ходу',
			'равномерная': 'равномерная',
			'с перебоями': 'с перебоями',
			'Другие замечания': 'Другие замечания',
			'Замер компрессии': 'Замер компрессии',
			'Заказывается и оплачивается отдельно!': 'Заказывается и оплачивается отдельно!',
			'цилиндр': 'цилиндр',
			'кг/см²': 'кг/см²',
			'Ємність основної батареї': 'Ёмкость основной батареи',
			'Пробіг авто': 'Пробег авто',
			'Сертифікати': 'Сертификаты',
			'Операційна система': 'Операционная система',
			'Автопілот': 'Автопилот',
			'Модем': 'Модем',
			'Навігація': 'Навигация',
			'Швидкісні зарядки': 'Скоростные зарядки',
		},
		uk: {
			'Осмотр двигателя': 'Огляд двигуна',
			'Работа двигателя': 'Робота двигуна',
			'норма': 'норма',
			'вибрация': 'вібрація',
			'шум': 'шум',
			'Охлаждающая жидкость': 'Охолоджуюча рідина',
			'прозрачная и чистая': 'прозора і чиста',
			'помутневшая': 'помутніла',
			'отсутствуют': 'відсутні',
			'присутствуют': 'присутні',
			'вкрапления масла': 'вкраплення масла',
			'понижен': 'знижений',
			'уровень антифриза': 'рівень антифризу',
			'Герметичность двигателя': 'Герметичність двигуна',
			'нет подтёков': 'немає підтікань',
			'есть подтёки': 'є підтікання',
			'масла из сальников': 'масла з сальників',
			'масла соединительных прокладок': 'масла із сполучних прокладок',
			'Маслозаливная горловина': 'Маслозаливочна горловина',
			'картерные газы': 'картерні гази',
			'эмульсия': 'емульсія',
			'Уровень масла в двигателе': 'Рівень масла в двигуні',
			'повышен': 'підвищений',
			'Старт мотора': 'Старт мотору',
			'плохо заводится': 'погано заводиться',
			'Выхлоп двигателя': 'Вихлоп двигуна',
			'прозрачный': 'прозорий',
			'сизый дым': 'сизий дим',
			'чёрный дым': 'чорний дим',
			'Работа на холостом ходу': 'Робота на холостому ходу',
			'равномерная': 'рівномірна',
			'с перебоями': 'з перебоями',
			'Другие замечания': 'Інші зауваження',
			'Замер компрессии': 'Замір компресії',
			'Заказывается и оплачивается отдельно!': 'Замовляється і оплачується окремо!',
			'цилиндр': 'циліндр',
			'кг/см²': 'кг/см²',
			'Ємність основної батареї': 'Ємність основної батареї',
			'Пробіг авто': 'Пробіг авто',
			'Сертифікати': 'Сертифікати',
			'Операційна система': 'Операційна система',
			'Автопілот': 'Автопілот',
			'Модем': 'Модем',
			'Навігація': 'Навігація',
			'Швидкісні зарядки': 'Швидкісні зарядки',
		}
	}
};
