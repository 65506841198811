export default {
	messages: {
		ru: {
			'Проверка салона и оборудования в нём': 'Проверка салона и оборудования в нём',
			'Обогреватель/кондиционер': 'Обогреватель / кондиционер',
			'работает': 'работает',
			'не работает': 'не работает',
			'Стеклоподъемники': 'Стеклоподъемники',
			'Состояние сидений': 'Состояние сидений',
			'норма': 'норма',
			'изношены': 'изношены',
			'пропалы': 'пропалы',
			'потертости': 'потертости',
			'загрязнения': 'загрязнения',
			'Регулировка сидений': 'Регулировка сидений',
			'Зеркала': 'Зеркала',
			'Стеклоочиститель/омыватель стекла': 'Стеклоочиститель / омыватель стекла',
			'Предупреждения на приборной панели': 'Предупреждения на приборной панели',
			'нет': 'нет',
			'есть': 'есть',
			'Кнопки, рычаги, переключатели': 'Кнопки, рычаги, переключатели',
			'Руль': 'Руль',
			'изношен': 'изношен',
			'в чехле': 'в чехле',
			'Аудио/Видео': 'Аудио / Видео',
			'Другие системы': 'Другие системы',
			'Повреждения материала обивки салона, сидений и дверных карт': 'Повреждения материала обивки салона, сидений и дверных карт',
			'трещины': 'трещины',
			'Посторонние запахи в салоне': 'Посторонние запахи в салоне',
			'присутствуют': 'присутствуют',
			'отсутствуют': 'отсутствуют',
			'Другие замечания': 'Другие замечания'
		},
		uk: {
			'Проверка салона и оборудования в нём': 'Перевірка салону і обладнання в ньому',
			'Обогреватель/кондиционер': 'Обігрівач / кондиціонер',
			'работает': 'працює',
			'не работает': 'не працює',
			'Стеклоподъемники': 'Cклопідйомники',
			'Состояние сидений': 'Cтан сидінь',
			'норма': 'норма',
			'изношены': 'зношені',
			'пропалы': 'пропали',
			'потертости': 'потертості',
			'загрязнения': 'забруднення',
			'Регулировка сидений': 'Регулювання сидінь',
			'Зеркала': 'Дзеркала',
			'Стеклоочиститель/ омыватель стекла': 'Склоочисник / омивач скла',
			'Предупреждения на приборной панели': 'Попередження на панелі приладів',
			'нет': 'немає',
			'есть': 'є',
			'Кнопки, рычаги, переключатели': 'Кнопки, важелі, перемикачі',
			'Руль': 'Кермо',
			'изношен': 'зношений',
			'в чехле': 'в чохлі',
			'Аудио/Видео': 'Аудіо / Відео',
			'Другие системы': 'Інші системи',
			'Повреждения материала обивки салона, сидений и дверных карт': 'Пошкодження матеріалу оббивки салону, сидінь і дверних карт',
			'трещины': 'тріщини',
			'Посторонние запахи в салоне': 'Сторонні запахи в салоні',
			'присутствуют': 'присутні',
			'отсутствуют': 'відсутні',
			'Другие замечания': 'Інші зауваження'
		}
	}
};
