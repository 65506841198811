import { mapGetters } from 'vuex';

import { AUTO_RIA_STATIONS, INSPECTION_CENTER_STATION_ID } from '../../../../constants/autotest';

export default {
  name: 'BodyInspection',
  i18n: require('./i18n').default,
  data() {
    return {
      selected: {
        bodyElements: {
          element1: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element2: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element3: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element4: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element5: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element6: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element7: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element8: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element9: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element10: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element11: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element12: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
          element13: {
            thickness: '',
            corrosion: false,
            repainting: false,
            putty: false,
            replacement: false,
            rectify: false,
            comment: '',
          },
        },
        glazing: '',
        tiresAndWheels: '',
        summary: '',
      },
      inspectionCenterStationId: INSPECTION_CENTER_STATION_ID,
    };
  },
  props: ['bodyInspection', 'pageBreak'],
  created() {
    if (this.bodyInspection) {
      this.selected = this.bodyInspection;
    }
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'TechnicalCheck/autotestData',
    }),
    stationId() {
      return this.autotestData?.stoData?.station_id;
    },
    isAutoRiaStation() {
      return AUTO_RIA_STATIONS.includes(this.stationId);
    },
    replacementPoint() {
      return this.isAutoRiaStation ? this.$t('Сколи') : this.$t('Замена');
    },
	alignmentPoint() {
	  return this.isAutoRiaStation ? this.$t('Подряпини') : this.$t('Рихтовка');
	},
  },
  watch: {
    selected: {
      handler() {
        this.$parent.$emit('changeBodyInspection', this.selected);
      },
      deep: true,
    },
  },
};
