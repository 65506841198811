import { mapGetters } from 'vuex';

import { ON_SITE_VERIFYING_STATION_ID } from '../../../../constants/autotest';

export default {
  name: 'InfoAuto',
  i18n: require('./i18n').default,
  data() {
    return {
      selected: {
        priceUAHstring: 0,
        priceUSDstring: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'TechnicalCheck/autotestData',
    }),
    link() {
      const code = this.langPrefix;
      const { linkToView, status, autoId } = this.autotestData.autoData;
      return `${code}${status === 24 ? `/marketplace/${autoId}` : linkToView}`;
    },
    reportMainPhoto() {
      return this.autotestData.orderData?.mainPhoto
        ? this.autotestData.orderData.mainPhoto
        : this.autotestData.autoData.photo;
    },
    onSiteInspection() {
      return this.autotestData?.stoData?.station_id === ON_SITE_VERIFYING_STATION_ID;
    },
    hideBaseInfo() {
      return Boolean(Number(this.$route.query.vinHistory));
    },
  },
  created() {
    this.selected.priceUAHstring = this.autotestData.autoData.priceUAH
      .toString()
      .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    this.selected.priceUSDstring = this.autotestData.autoData.priceUSD
      .toString()
      .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
  },
};
