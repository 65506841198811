export default {
	messages: {
		ru: {
			'Базовая информация': 'Базовая информация',
			'Дата и время диагностики': 'Дата и время диагностики',
			'Пробег (км)': 'Пробег (км)',
			'VIN код': 'VIN код',
			'Вы указали неверный  VIN-код. Пожалуйста, повторите попытку.': 'Вы указали неверный  VIN-код. Пожалуйста, повторите попытку.',
			'Визуальные следы замены номера кузова': 'Визуальные следы замены номера кузова',
			'присутствуют': 'присутствуют',
			'отсутствуют': 'отсутствуют',
			'Примечание к заказу от заказчика': 'Примечание к заказу от заказчика'
		},
		uk: {
			'Базовая информация': 'Базова інформація',
			'Дата и время диагностики': 'Дата і час діагностики',
			'Пробег (км)': 'Пробіг (км)',
			'VIN код': 'VIN код',
			'Вы указали неверный  VIN-код. Пожалуйста, повторите попытку.': 'Ви вказали невірний VIN-код. Будь ласка, повторіть спробу.',
			'Визуальные следы замены номера кузова': 'Візуальні сліди заміни номера кузова',
			'присутствуют': 'присутні',
			'отсутствуют': 'відсутні',
			'Примечание к заказу от заказчика': 'Примітка до замовлення від замовника'
		}
	}
};
