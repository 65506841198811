import { mapActions, mapGetters } from 'vuex';
import TitleBar from './TitleBar/index.vue';
import RunningDiagnostics from './RunningDiagnostics/index.vue';
import CheckingCollapsConvergence from './CheckingCollapsConvergence/index.vue';
import EngineInspection from './EngineInspection/index.vue';
import BodyInspection from './BodyInspection/index.vue';
import InteriorInspection from './InteriorInspection/index.vue';
import CheckingLight from './CheckingLight/index.vue';
import TestDrive from './TestDrive/index.vue';
import ComputerDiagnostics from './ComputerDiagnostics/index.vue';
import PhotoReport from './PhotoReport/index.vue';
import ApproveBar from './ApproveBar/index.vue';
import InfoAuto from './InfoAuto/index.vue';
import InfoSTO from './InfoSTO/index.vue';
import InfoBase from './InfoBase/index.vue';
import ModalBar from './ModalBar/index.vue';
import IsShowBar from './IsShowBar/index.vue';
import GroupEvaluation from './GroupEvaluation/index.vue';
import InspectionServiceStation from './InspectionServiceStation/index.vue';
import CompressionMeasurement from './CompressionMeasurement/index.vue';

import {
  ELECTRIC_FUEL_ID,
  EXAMPLE_TECH_REPORT_ID,
  ON_SITE_VERIFYING_STATION_ID,
  EXAMPLE_ELECTRIC_TECH_REPORT_ID,
} from '../../../constants/autotest';

export default {
  i18n: require('./i18n').default,
  data() {
    return {
      mainObject: {
        orderId: '',
        customerId: '',
      },
      isShow: false,
      saveResponse: {},
      isElectric: false,
    };
  },
  init: require(`./_init/${_TARGET_}`),
  components: {
    'title-bar': TitleBar,
    'running-diagnosis': RunningDiagnostics,
    'checking-collaps-convergence': CheckingCollapsConvergence,
    'engine-inspection': EngineInspection,
    'body-inspection': BodyInspection,
    'interior-inspection': InteriorInspection,
    'checking-light': CheckingLight,
    'test-drive': TestDrive,
    'computer-diagnostics': ComputerDiagnostics,
    'photo-report': PhotoReport,
    'approve-bar': ApproveBar,
    'info-auto': InfoAuto,
    'info-sto': InfoSTO,
    'info-base': InfoBase,
    'modal-bar': ModalBar,
    'is-show-bar': IsShowBar,
    'group-evaluation': GroupEvaluation,
    'inspection-service-station': InspectionServiceStation,
    'compression-measurement': CompressionMeasurement,
  },
  metaInfo() {
    return {
      title: `AUTO.RIA™ — Перевірені авто на AUTO.RIA ${this.$t('Результаты технической проверки')}`,
      meta: [
        { name: 'description', content: '' },
        { name: 'keywords', content: '' },
        { property: 'og:title', content: '' },
        { property: 'og:description', content: '' },
        { name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/' },
        {
          rel: 'stylesheet',
          media: 'screen',
          type: 'text/css',
          href: 'https://css.riastatic.com/stylesheets/checklist_sto.css?v=1.01',
        },
        {
          rel: 'stylesheet',
          media: 'print',
          type: 'text/css',
          href: 'https://css.riastatic.com/stylesheets/checklist_sto-print.css?v=1.01',
        },
      ],
    };
  },
  name: 'TechnicalCheck',
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'TechnicalCheck/autotestData',
      reportData: 'TechnicalCheck/reportData',
      userData: 'Common/userData',
    }),
    orderId() {
      return this?.autotestData?.orderData?.orderId;
    },
    stationId() {
		   return this?.autotestData?.stoData?.station_id;
    },
    isOnSiteVerifying() {
      return this.stationId === ON_SITE_VERIFYING_STATION_ID;
    },
    isCustomer() {
      return this?.autotestData?.isCustomer;
    },
    showEvaluation() {
      return this.orderId
				   && this.isCustomer
				   && ![EXAMPLE_TECH_REPORT_ID, EXAMPLE_ELECTRIC_TECH_REPORT_ID].includes(this.orderId);
    },
    onSiteInspection() {
      return this.autotestData?.stoData?.station_id === ON_SITE_VERIFYING_STATION_ID;
    },
  },
  created() {
    if (Object.keys(this.reportData).length && (this.autotestData.isSTO || this.isCustomer)) {
      this.mainObject = this.reportData?.mainObject || {};
    }

    this.$on('saveReport', function () {
      if (this.autotestData.isSTO) {
        // сохранение объекта формы редирект на страницу благодарности
        this.$http.post('/report/diagnostic/', { mainObject: this.mainObject })
          .then(function (resp) {
            this.$refs.approve.check(resp.status);
          })
          .catch(function (resp) {
            this.$refs.approve.check(resp.status);
          });
      }
    });
    if (this?.autotestData?.accessDenied) {
      this.mainObject.orderId = this.autotestData?.orderData?.orderId;
      this.mainObject.customerId = this.autotestData?.orderData?.customerId;
    }

    this.$on('onShowModal', function (flag) {
      this.isShow = flag;
    });

    // створюємо основний обєкт, для збереження
    this.$on('changeBaseInfo', function (infoBase) {
      this.mainObject.infoBase = infoBase;
    });

    this.$on('changeRunningDiagnostics', function (runningDiagnostics) {
      this.mainObject.runningDiagnostics = runningDiagnostics;
    });

    this.$on('changeEngineInspection', function (engineInspection) {
      this.mainObject.engineInspection = engineInspection;
    });

    this.$on('changeBodyInspection', function (bodyInspection) {
      this.mainObject.bodyInspection = bodyInspection;
    });

    this.$on('addPhotoReportLink', function (photoReportLink) {
      this.mainObject.photoReportLink = photoReportLink;
    });

    this.$on('addVideoReportLink', function (videoReportLink) {
      this.mainObject.videoReportLink = videoReportLink;
    });

    this.$on('changeInteriorInspection', function (interiorInspection) {
      this.mainObject.interiorInspection = interiorInspection;
    });

    this.$on('changeCheckingLight', function (checkingLight) {
      this.mainObject.checkingLight = checkingLight;
    });

    this.$on('changeTestDrive', function (testDrive) {
      this.mainObject.testDrive = testDrive;
    });

    this.$on('changeComputerDiagnostics', function (computerDiagnostics) {
      this.mainObject.computerDiagnostics = computerDiagnostics;
    });

    this.$on('changePhotoReport', function (photoReport) {
      this.mainObject.photoReport = photoReport;
    });

    this.$on('changeApproveBar', function (approveBar) {
      this.mainObject.approveBar = approveBar;
    });

    this.$on('changeCheckingCollapsConvergence', function (checkingCollapsConvergence) {
      this.mainObject.checkingCollapsConvergence = checkingCollapsConvergence;
    });

    this.$on('changeCompressionMeasurement', function (compressionMeasurement) {
      this.mainObject.compressionMeasurement = compressionMeasurement;
    });
  },
  methods: {
    ...mapActions({
      fetchAdvertisements: 'graphql/advertisements',
      fetOrderById: 'others/autotest/fetchOrderById',
    }),
  },
  mounted() {
    const orderId = Number(this.$route.params.orderId);

    if (orderId) {
      this
        .fetOrderById(orderId)
        .then(({ fuel_id } = {}) => {
          this.isElectric = fuel_id === ELECTRIC_FUEL_ID;
        });
    }
  },
};
