import {mapGetters} from 'vuex';

export default {
	name: 'ModalBar',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				showModal: false
			},
			photos: [

			]
		};
	},
	props: ['isShow', 'files'],
	created() {
		// this.photos = this.reportPhotos;

	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			reportPhotos: 'TechnicalCheck/reportPhotos'
		})
	},
	methods: {
		closeModal() {
			this.selected.showModal = false;
			this.$parent.$emit('onShowModal', false);
		},
		showModal() {
			this.selected.showModal = true;
		}
	},
	watch: {
		'isShow': {
			handler() {
				if (this.isShow) {
					this.selected.showModal = true;
				}
			}
		},
		'files': {
			handler() {
				this.photos = this.files;
			}
		},
		'selected.showModal': {
			handler() {
				this.selected.showModal ?
					document.getElementsByTagName('body')[0].setAttribute( 'class', 'modal-open page-overflow' ) :
					document.getElementsByTagName('body')[0].setAttribute( 'class', '' );
			},
			deep: true
		}
	}
};
