import { mapGetters } from 'vuex';

export default {
  name: 'EngineInspection',
  i18n: require('./i18n').default,
  data() {
    return {
      selected: {
        coolant: {
          value: undefined,
          blottingOil: undefined,
          antifreezeLevel: undefined,
        },
        engineOperation: {
          value: undefined,
          option: {
            noise: undefined,
            vibration: undefined,
          },
        },
        oilFillerNeck: {
          value: undefined,
          option: {
            crankcaseGases: undefined,
            emulsion: undefined,
          },
        },
        motorStart: undefined,
        extraneousNoise: undefined,
        engineTightness: {
          oilFromStuffingBoxes: undefined,
          oilConnectingGaskets: undefined,
        },
        engineOilLevel: undefined,
        engineExhaust: undefined,
        idling: undefined,
        otherRemarks: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'TechnicalCheck/autotestData',
    }),
  },
  props: ['engineInspection', 'pageBreak', 'isOnSiteVerifying'],
  created() {
    if (this.engineInspection) {
      this.selected = this.engineInspection;
    }
  },
  watch: {
    selected: {
      handler() {
        this.$parent.$emit('changeEngineInspection', this.selected);
      },
      deep: true,
    },
    'selected.engineOperation.value': {
      handler(val) {
        if (val) {
          this.selected.engineOperation.option.vibration = false;
          this.selected.engineOperation.option.noise = false;
        }
      },
    },
    'selected.engineOperation.option.vibration': {
      handler(val) {
        if (val) {
          this.selected.engineOperation.value = false;
        }
      },
    },
    'selected.engineOperation.option.noise': {
      handler(val) {
        if (val) {
          this.selected.engineOperation.value = false;
        }
      },
    },
    'selected.oilFillerNeck.value': {
      handler(val) {
        if (val) {
          this.selected.oilFillerNeck.option.crankcaseGases = false;
          this.selected.oilFillerNeck.option.emulsion = false;
        }
      },
    },
    'selected.oilFillerNeck.option.crankcaseGases': {
      handler(val) {
        if (val) {
          this.selected.oilFillerNeck.value = false;
        }
      },
    },
    'selected.oilFillerNeck.option.emulsion': {
      handler(val) {
        if (val) {
          this.selected.oilFillerNeck.value = false;
        }
      },
    },
  },
};
