export default {
  messages: {
    ru: {
      'Набір діагностики': 'Набор диагностики',
      'Она включает:': 'Она включает:',
      'Выездная проверка – это первичный осмотр автомобиля, который проводят специалисты': 'Выездная проверка – это первичный осмотр автомобиля, который проводят специалисты',
      'Центра проверок': 'Центра проверок',
    },
    uk: {
      'Набір діагностики': 'Набір діагностики',
      'Она включает:': 'Вона включає:',
      'Выездная проверка – это первичный осмотр автомобиля, который проводят специалисты': 'Виїзна перевірка - це первинний огляд автомобіля, який проводять спеціалісти',
      'Центра проверок': 'Центру перевірок',
    },
  },
};
