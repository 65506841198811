import { mapGetters, mapActions } from 'vuex';

import types from './types.json';

import Evaluation from '../../Common/Evaluation/index.vue';

export default {
	name: 'GroupEvaluation',
	i18n: require('./i18n').default,
	props: {
		orderId: {
			type: Number,
			required: true,
		}
	},
	data() {
		return {
			types: {},
		};
	},
	async mounted() {
		const existed = await this.fetchReviews(this.orderId);

		this.types = Object
			.entries(existed)
			.reduce((memo, [key, sent]) => {
				if (!sent && types[key]) {
					memo[key] = types[key];
				}
				return memo;
			}, {});
	},
	components: {
		evaluation: Evaluation,
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
		}),
	},
	methods: {
		...mapActions({
			sendReview: 'TechnicalCheck/sendReview',
			fetchReviews: 'TechnicalCheck/fetchReviews',
		}),
		async submit(data) {
			const obj = this.types[data.type];
			obj.loader = true;
			const { saved } = await this.sendReview({ ...data, type: obj.id });
			obj.loader = false;
			obj.sent = saved;
		},
	},
};
