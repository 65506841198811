export default {
	name: 'Evaluation',
	props: {
		title: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'start',
		},
		reviewSend: {
			type: Boolean,
			required: false,
		},
		loader: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			stars: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
			textArea: '',
			selectedStar: 0,
		};
	},
	methods: {
		submit() {
			this.$emit('submit', {
				rating: this.selectedStar,
				text: this.textArea,
				type: this.type,
			})
		},
	},
	watch: {
		selectedStar: function () {
			this.$emit('selectedStar', { value: Number(this.selectedStar) });
		}
	},
	i18n: {
		messages: {
			ru: {
				'Напишите, что Вам понравилось, а что нет': 'Напишите, что Вам понравилось, а что нет',
				'Отправить отзыв': 'Отправить отзыв',
				'Спасибо, мы все учтем и обязательно станем лучше': 'Спасибо, мы все учтем и обязательно станем лучше',
				'Спасибо, мы стараемся для вас!': 'Спасибо, мы стараемся для вас!',
			},
			uk: {
				'Напишите, что Вам понравилось, а что нет': 'Напишіть, що Вам сподобалось, а що ні',
				'Отправить отзыв': 'Відправити відгук',
				'Спасибо, мы все учтем и обязательно станем лучше': 'Дякуємо, ми все врахуємо й обов\'язково станемо краще',
				'Спасибо, мы стараемся для вас!': 'Дякуємо, ми стараємось для вас!',
			}
		}
	}
};
