
import { mapGetters } from 'vuex';

export default {
	name: 'InspectionServiceStation',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
		}),
	},
};
