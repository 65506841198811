import { mapGetters } from 'vuex';

export default {
  name: 'TitleBar',
  i18n: require('./i18n').default,
  props: ['isOnSiteVerifying'],
  data() {
    return {
      selected: {},
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'TechnicalCheck/autotestData',
    }),
    title() {
      return this.isOnSiteVerifying
        ? `${this.$t('Виїзна перевірка від Центру перевірок AUTO.RIA')}`
        : `${this.$t('Результаты технической проверки')}`;
    },
    mtSize() {
      return this.isOnSiteVerifying ? 5 : 20;
    },
  },
};
