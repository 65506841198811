import {mapGetters} from 'vuex';

export default {
	name: 'CheckingLight',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				front: {
					right: {
						near: '',
						further: '',
						turn: '',
						dimension: '',
						antiFogLamp: ''
					},
					left: {
						near: '',
						further: '',
						turn: '',
						dimension: '',
						antiFogLamp: ''
					}
				},
				rear: {
					right: {
						turn: '',
						reverse: '',
						stop: '',
						dimension: '',
						licensePlate: ''
					},
					left: {
						turn: '',
						reverse: '',
						stop: '',
						dimension: '',
						licensePlate: ''
					}
				},
				angleAdjustment: '',
				xenonHeadlight: '',
				otherRemarks: ''
			}
		};
	},
	props: ['checkingLight', 'pageBreak'],
	created() {
		if (this.checkingLight) {
			this.selected = this.checkingLight;
		}
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'TechnicalCheck/autotestData'
		})
	},
	watch: {
		'selected': {
			handler() {
				this.$parent.$emit('changeCheckingLight', this.selected);
			},
			deep: true
		}
	}
};
