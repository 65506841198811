export default {
	messages: {
		uk: {
			'Наскільки вірогідно, що ви порекомендуєте сервіс перевірок авто на AUTO.RIA?': 'Наскільки вірогідно, що ви порекомендуєте сервіс перевірок авто на AUTO.RIA?',
			'Оцініть якість роботи СТО, де проходила перевірка:': 'Оцініть якість роботи СТО, де проходила перевірка:',
			'Оцініть роботу менеджера AUTO.RIA, який організовував перевірку вашого авто:': 'Оцініть роботу менеджера AUTO.RIA, який організовував перевірку вашого авто:',
		},
		ru: {
			'Наскільки вірогідно, що ви порекомендуєте сервіс перевірок авто на AUTO.RIA?': 'Насколько возможно, что вы посоветуете сервис проверок авто на AUTO.RIA?',
			'Оцініть якість роботи СТО, де проходила перевірка:': 'Оцените качество работы СТО, где проходила проверка:',
			'Оцініть роботу менеджера AUTO.RIA, який організовував перевірку вашого авто:': 'Оцените работу менеджера AUTO.RIA, который организовывал проверку вашего авто:',
		},
	}
};
